/* eslint-disable react/no-danger */
import * as React from 'react';
import { PageProps } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import {
  graphql2post,
  SiteQueryProps,
  Author,
  AllMdxPost,
  PageContextProps,
} from '../../utils/graphql';
import { useLocalizedItem } from '../../utils/use-localized-items';
import DefaultLayout from '../layouts/default-layout';
import PostsList from './posts-list';
import NewsFooter from './news-footer';

type AuthorPostsQueryProps = {
  site: SiteQueryProps;
  authorJson: Author;
  allMdx: AllMdxPost;
};

type AuthorPostsProps = PageProps<AuthorPostsQueryProps, PageContextProps>;

const AuthorPosts: React.VoidFunctionComponent<AuthorPostsProps> = ({
  data: {
    site,
    authorJson,
    allMdx: { nodes },
  },
  pageContext,
}: AuthorPostsProps) => {
  const { t } = useTranslation('news');

  const allPosts = React.useMemo(
    () =>
      nodes.map((node) => {
        const post = graphql2post(node);
        post.authors = [authorJson];
        return post;
      }),
    [nodes, authorJson],
  );

  const posts = useLocalizedItem(allPosts);

  const title = t('authors.postsBy', { fullName: authorJson.fullName });

  return (
    <DefaultLayout
      headTitle={t('suffixed-head-title', { title })}
      headDescription={title}
      headImageSource={authorJson.avatar && getSrc(authorJson.avatar)}
      headImageAlt={authorJson.fullName}
      lang={pageContext.locale}
      wrapInContainer
    >
      <h1 id="authorPosts" className="text-7xl text-black">
        {title}
      </h1>
      <div
        className="my-8 text-center text-sm font-medium text-black"
        dangerouslySetInnerHTML={{ __html: authorJson.bio ?? '' }}
      />
      <PostsList posts={posts} siteUrl={site.siteMetadata.siteUrl} pageContext={pageContext} />
      <NewsFooter pageContext={pageContext} />
    </DefaultLayout>
  );
};

export default AuthorPosts;
